import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { ILearningSupport } from '@/assets/types/learning/LearningSupportTypes'
import type { ILearningGrain } from '@/assets/types/learning/LearningGrainTypes'
import { LearningQuizDto } from '@/assets/DTO/learning/learningQuiz.dto'

export class LearningGrainResponseDto implements ILearningGrain {
  id: number
  title: string
  status: LearningEnums.Status
  points: number
  order: number
  quizPre: ILearningQuiz
  quizPost: ILearningQuiz
  learningSupports: ILearningSupport[]

  constructor(data: ILearningGrain, order?: number) {
    this.id = data.id
    this.title = data.title
    this.status = data.status
    this.points = data.points
    this.order = order ?? data.order
    this.quizPre = data.quizPre
    this.quizPost = data.quizPost
    this.learningSupports = data.learningSupports.sort((a, b) =>  a.order - b.order ).map((support, i) => ({ ...support, order: i + 1 }))
  }

  supportsCount(): number {
    return this.learningSupports.length
  }

  quizsAreValid(): boolean {
    return new LearningQuizDto(this.quizPre).isValid() && new LearningQuizDto(this.quizPost).isValid()
  }
}
