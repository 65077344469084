<script setup lang="ts">
import { inject, onMounted, ref, type Ref } from 'vue'
import type { INabooContentDiagnosticDto } from '@/assets/DTO/nabooContent/nabooContent.types.dto'
import QuizView from '@/components/quiz/QuizView.vue'

const dialogRef: any = inject('dialogRef')
const diagnostic: Ref<INabooContentDiagnosticDto> = ref({} as INabooContentDiagnosticDto)
const subtitle: Ref<string> = ref('')

onMounted(() => {
  diagnostic.value = dialogRef.value?.data?.diagnostic
  subtitle.value = dialogRef.value?.data?.subtitle

  console.log(diagnostic.value)
})
</script>

<template>
  <div class="flex flex-column gap-3 justify-content-start">
    <h3 class="flex-1">{{ subtitle }}</h3>

    <quiz-view
      v-for="task in diagnostic.tasks"
      :key="`task_${task.id}_quiz_${task.quiz.id}`"
      :subtitle="task.title"
      :quiz="task.quiz"
    />
  </div>
</template>
