<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { default as PProgressSpinner } from 'primevue/progressspinner'
import { default as PDataTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PInputText } from 'primevue/inputtext'
import { default as PButton } from 'primevue/button'
import { default as PSplitButton } from 'primevue/splitbutton'
import { default as PDropdown } from 'primevue/dropdown'

import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PInputGroupAddon } from 'primevue/inputgroupaddon'

import { FilterMatchMode } from 'primevue/api'
import type { IUser, IUserDetails } from '@/assets/types/UserTypes'
import { Gender, Role } from '@/assets/types/UserTypes'
import { useAuthStore } from '@/stores/auth'
import { UpdateUserStateInputDto } from '@/assets/DTO/users/user.dto'

const { isAdmin } = storeToRefs(useAuthStore())
const { fetchAllUsersWithDetails, setVerifiedEmailState, setBlockedState } = useUserStore()
const { loading, usersList } = storeToRefs(useUserStore())

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  schoolName: { value: null, matchMode: FilterMatchMode.EQUALS }
})

const transformedUserList = computed(() => {
  return usersList.value.map((user: IUser) => {
    return {
      ...user,
      roles: user.roles.map((role) => Role.label(role)),
      gender: Gender.label(user.gender)
    }
  })
})

const schoolOptions = computed(() => {
  return Array.from(
    new Set(usersList.value.map((user: IUser) => (user as IUserDetails).schoolName))
  ).map((school) => {
    return school === null ? { label: 'Tous', value: null } : { label: school, value: school }
  })
})

function toggleRedactedEmail(e: MouseEvent, email: string) {
  const text = (e.target as HTMLElement).innerText
  if (text.includes('***')) return email
  else return redactedEmail(email)
}

function redactedEmail(email: string) {
  const part = email.split('@')[0]
  return part.slice(0, 2) + '***' + part.slice(-2) + '@' + email.split('@')[1]
}

/**
 * Return a menu items for each row of user
 * @param data
 */
function getBtnActions(data: IUser) {
  const verifyEmail = () => {
    if (window.confirm('Voulez-vous vraiment marquer cet email comme vérifié ?')) {
      setVerifiedEmailState(
        new UpdateUserStateInputDto({ userIdentifier: data.identifier, state: true })
      )
    }
  }
  const blockedAct = () => {
    if (confirm('Voulez-vous vraiment bloquer/débloquer cet utilisateur ?'))
      setBlockedState(
        new UpdateUserStateInputDto({ userIdentifier: data.identifier, state: !data.isBlocked })
      )
  }

  return [
    {
      label: 'Vérifier email',
      icon: 'pi pi-verified',
      command: verifyEmail
    },
    {
      label: data.isBlocked ? "Débloquer l'utilisateur" : "Bloquer l'utilisateur",
      icon: data.isBlocked ? 'pi pi-lock-open' : 'pi pi-lock',
      command: blockedAct
    }
  ]
}

onMounted(async () => {
  await fetchAllUsersWithDetails()
})
</script>

<template>
  <div class="p-4 grid h-screen overflow-y-auto flex align-items-start">
    <template v-if="loading">
      <p-progress-spinner />
    </template>

    <template v-else>
      <div class="col-12 grid">
        <!-- Header -->
        <div class="col-12 flex flex-column">
          <div class="col-12 flex">
            <div class="col-10">
              <h1 class="text-left">Utilisateurs</h1>
              <small class="text-color">Retrouvez ici la liste de tous les utilisateurs.</small>
            </div>

            <div class="col-2 flex justify-content-end">
              <router-link :to="{ name: 'create-user' }">
                <p-button label="Ajouter un utilisateur" icon="pi pi-plus" size="small" />
              </router-link>
            </div>
          </div>
        </div>

        <!-- Datatable -->
        <div class="col-12 grid flex-1">
          <p-data-table
            :value="transformedUserList"
            dataKey="id"
            class="text-sm col-12"
            size="small"
            :loading="loading"
            v-model:filters="filters"
            removableSort
            sortMode="multiple"
            scrollable
            paginator
            :rows="10"
            :rowsPerPageOptions="[5, 10, 20, 50]"
            :globalFilterFields="[
              'roles',
              'firstname',
              'lastname',
              'email',
              'gender',
              'schoolName'
            ]"
          >
            <template #header>
              <div class="col-12 grid flex justify-content-start align-items-center max-h-min">
                <p-input-group class="col-4 max-h-min h-4rem">
                  <p-input-group-addon>
                    <i class="pi pi-search"></i>
                  </p-input-group-addon>
                  <p-input-text
                    autocomplete="off"
                    data-lpignore="true"
                    data-form-type="other"
                    placeholder="Recherche par rôle, nom, prénom, email, genre, école"
                    v-model="filters['global'].value"
                    size="small"
                  />
                </p-input-group>
                <p-input-group class="col-4 max-h-min h-4rem">
                  <p-input-group-addon>
                    <i class="pi pi-building"></i>
                  </p-input-group-addon>
                  <p-dropdown
                    v-model="filters['schoolName'].value"
                    :options="schoolOptions"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Filtrer par école"
                    size="small"
                  />
                </p-input-group>
              </div>
            </template>
            <template #empty> Aucun utilisateur trouvé.</template>
            <template #loading> Loading customers data. Please wait.</template>

            <!-- Body -->
            <!-- Avatar -->
            <p-column
              field="avatar"
              header=""
              class="col-fixed min-w-min max-w-min"
              style="width: 50px"
            >
              <template #body="{ data }">
                <div class="Center">
                  <img :src="data.picture" alt="avatar" class="Avatar Center w-auto" />
                </div>
              </template>
            </p-column>

            <p-column field="role" header="Rôle" class="col-2 min-w-min max-w-max">
              <template #body="{ data }">
                <ul class="Center">
                  <li v-for="(it, idx) in (data as IUser).roles" :key="idx">
                    {{ it }}
                  </li>
                </ul>
              </template>
            </p-column>

            <p-column
              field="firstname"
              :sortable="true"
              header="Prénom"
              class="col-1 min-w-min max-w-min"
            >
              <template #body="{ data }">
                <p class="Center">{{ (data as IUser).firstname }}</p>
              </template>
            </p-column>

            <p-column
              field="lastname"
              :sortable="true"
              header="Nom"
              class="col-1 min-w-min max-w-max"
            >
              <template #body="{ data }">
                <p class="Center">{{ (data as IUser).lastname }}</p>
              </template>
            </p-column>

            <p-column
              field="email"
              :sortable="true"
              header="Email"
              class="col-2 min-w-min max-w-max"
            >
              <template #body="{ data }">
                <div
                  class="Email"
                  @click.exact="
                    (e) =>
                      ((e.target as HTMLElement).innerText = toggleRedactedEmail(e, data.email))
                  "
                  :id="'email_' + data.id"
                >
                  <p class="Center">{{ redactedEmail((data as IUser).email) }}</p>
                </div>
              </template>
            </p-column>

            <p-column
              field="gender"
              header="Genre"
              :sortable="true"
              class="col-1 min-w-min max-w-max"
            >
              <template #body="{ data }">
                <p class="Center">{{ (data as IUser).gender }}</p>
              </template>
            </p-column>

            <p-column header="École" :sortable="true" class="col-1 min-w-min max-w-max">
              <template #body="{ data }">
                <p class="Center">{{ (data as IUserDetails).schoolName }}</p>
              </template>
            </p-column>

            <!-- Email is verified -->
            <p-column
              field="isEmailVerified"
              header="Email vérifié"
              dataType="boolean"
              class="col-1 min-w-min max-w-max"
            >
              <template #body="{ data }">
                <div class="flex justify-content-around align-content-center align-items-center">
                  <span class="Center">
                    <i
                      class="pi"
                      :class="{
                        'pi-verified text-green-500 text-xl': data.isEmailVerified,
                        'pi-times-circle text-red-400': !data.isEmailVerified
                      }"
                    ></i>
                  </span>
                </div>
              </template>
            </p-column>

            <!-- User is locked -->
            <p-column
              field="isLocked"
              header="Util. bloqué"
              dataType="boolean"
              class="col-1 min-w-min max-w-max"
            >
              <template #body="{ data }">
                <div class="flex justify-content-around align-content-center align-items-center">
                  <span class="Center">
                    <i
                      class="pi"
                      :class="{
                        'pi-lock-open text-green-500': !data.isBlocked,
                        'pi-lock text-red-400': data.isBlocked
                      }"
                    ></i>
                  </span>
                </div>
              </template>
            </p-column>

            <p-column
              field="id"
              header="Actions"
              data-type="number"
              class="col min-w-min max-w-max"
            >
              <template #body="{ data }">
                <div class="flex justify-content-around align-content-center align-items-center">
                  <span class="Left">
                    <p-split-button
                      v-if="isAdmin"
                      size="small"
                      label="Modifier"
                      @click.prevent="
                        $router.push({
                          name: 'edit-user',
                          params: { userIdentifier: data.identifier }
                        })
                      "
                      :model="getBtnActions(data)"
                      text
                      icon="pi pi-pencil"
                      menuButtonIcon="pi pi-ellipsis-v"
                    />
                  </span>
                </div>
              </template>
            </p-column>
          </p-data-table>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.Datatable {
  font-size: 0.8rem;

  .p-column-title {
    font-size: 0.8rem;
  }

  .Avatar {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }

  .Center {
    font-size: 0.8rem;
    text-align: center;
  }

  .Points {
    font-size: 0.8rem;
    text-align: right;
  }

  .p-column-filter-row {
    width: auto;
  }
}
</style>
