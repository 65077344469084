<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import PChart from 'primevue/chart'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart, type ChartOptions } from 'chart.js'
import { storeToRefs } from 'pinia'
import { useSchoolClassroomStore } from '@/stores/schoolClassroom'
import { default as PDataTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PInputText } from 'primevue/inputtext'
import { default as PDropdown } from 'primevue/dropdown'
import { default as PInputGroup } from 'primevue/inputgroup'
import type { IUser } from '@/assets/types/UserTypes'
import { FilterMatchMode } from 'primevue/api'
import DashboardBaseBlock from '@/views/dashboard/blocks/DashboardBaseBlock.vue'
import ThemeTableScore from '@/views/dashboard/blocks/progress/ThemeTableScore.vue'
import { EvaluationRange } from '@/assets/types/enums'

// Interface data
interface IScoreDiagnosticData {
  labels: string[]
  datasets: {
    label: 'Défi' | 'Efforts à faire' | 'Force'
    backgroundColor: string
    data: number[]
  }[]
}

// Référencer les plugins de Chart.js
Chart.register(ChartDataLabels)

const { fetchSchoolClassroomScoreDiagnostic } = useSchoolClassroomStore()

// Variables, props
const { scoreDiagnostic } = storeToRefs(useSchoolClassroomStore())
const props = defineProps<{
  schoolId: number
  groupId: number
  courseId: number
}>()

onMounted(async () => {
  await fetchSchoolClassroomScoreDiagnostic(props.schoolId, props.groupId, props.courseId)
})

const dataSource = computed(() => {
  return scoreDiagnostic.value.data?.map((student) => {
    return {
      ...student,
      teacherFullname: student.teacherFirstname
        ? `${student.teacherFirstname} ${student.teacherLastname}`
        : undefined
    }
  })
})

const teachersOptions = computed(() => {
  const teachers = [
    ...new Set(
      dataSource.value
        ?.filter((student) => student.teacherFullname)
        ?.map((student) => student.teacherFullname)
    )
  ]?.map((teacher) => {
    return {
      label: teacher,
      value: teacher
    }
  })

  return teachers ? [{ label: 'Tous les enseignants', value: null }, ...teachers] : []
})

const showStudentsDetail = ref(false)
/*
 *
 * CHART
 *
 * */

// Computed method for chart
const transformDataForChart = computed(() => {
  const data = scoreDiagnostic.value.data
  // Initialisation des tâches regroupées
  const groupedTasks: IScoreDiagnosticData = {
    labels: [],
    datasets: [
      {
        label: 'Défi',
        backgroundColor: '#FF6F61', // Rouge
        data: []
      },
      {
        label: 'Efforts à faire',
        backgroundColor: '#FBA86F', // Jaune
        data: []
      },
      {
        label: 'Force',
        backgroundColor: '#32CD32', // Vert
        data: []
      }
    ]
  }

  // Map pour compter les scores par évaluation pour chaque tâche
  const taskScores = new Map<number, { defi: number; effortsAFaire: number; force: number }>()

  // Parcours des étudiants et regroupement par tâche
  data.forEach((student) => {
    student.tasks.forEach((task) => {
      if (!taskScores.has(task.taskId)) {
        taskScores.set(task.taskId, { defi: 0, effortsAFaire: 0, force: 0 })
      }

      const taskScore = taskScores.get(task.taskId)
      if (taskScore) {
        // Catégorisation selon le diagEvalRangeName
        if (task.diagEvalRangeName === EvaluationRange.Values.DEFI) {
          taskScore.defi += 1
        } else if (task.diagEvalRangeName === EvaluationRange.Values.EFFORT_A_FAIRE) {
          taskScore.effortsAFaire += 1
        } else if (task.diagEvalRangeName === EvaluationRange.Values.FORCE) {
          taskScore.force += 1
        }
      }
    })
  })

  // Construction des labels et des datasets
  taskScores.forEach((score, taskId) => {
    const taskLabel =
      data[0].tasks.find((task) => task.taskId === taskId)?.taskTitle || `Tâche ${taskId}`

    // Ajout du label (titre de la tâche)
    groupedTasks.labels.push(taskLabel)

    // Ajout des données aux datasets
    groupedTasks.datasets[0].data.push(score.defi) // Défi
    groupedTasks.datasets[1].data.push(score.effortsAFaire) // Efforts à faire
    groupedTasks.datasets[2].data.push(score.force) // Force
  })

  return groupedTasks
})

const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        color: '#495057',
        font: {
          size: 14
        }
      }
    },
    tooltip: {
      enabled: true
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
      color: '#000',
      font: {
        size: 12,
        weight: 'bold'
      },
      formatter: (value) => value // Affiche la valeur
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#495057',
        font: {
          size: 14
        },
        callback: function (value: any, index: number, ticks: any) {
          const label = this.getLabelForValue(value)
          return label.length > 20 ? label.substring(0, 17) + '...' : label
        }
      },
      grid: {
        display: false
      },
      barPercentage: 0.8 // Ajuste l'espacement entre les groupes
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: '#495057',
        font: {
          size: 14
        },
        stepSize: 5 // Ajuste les intervalles sur l'axe Y pour une meilleure lisibilité
      },
      grid: {
        color: '#E5E5E5'
      }
    }
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10
    }
  }
}

/*
 *
 *
 * TABLE DATA & filters
 *
 * */
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  firstname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  lastname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  teacherFullname: { value: null, matchMode: FilterMatchMode.EQUALS }
})
</script>

<template>
  <dashboard-base-block title="Diagnostic">
    <p>Le diagnostic est un quiz réalisé à chaque début de nouveau parcours.</p>
    <p>
      Le diagnostic est découpé en thématiques, qui sont ensuite abordées indépendamment dans chaque
      module.
    </p>
    <div
      class="border-1 border-bo-brand-secondary p-4 flex flex-column gap-4 border-round-2xl col-10"
    >
      <h4 class="heading medium">Répartition des élèves par intervalles de scores</h4>
      <div>
        <p-chart type="bar" :data="transformDataForChart" :options="chartOptions" />
      </div>
    </div>

    <a class="cursor-pointer block col-12" @click="showStudentsDetail = !showStudentsDetail">
      Afficher le détail par élève
      <i class="pi" :class="showStudentsDetail ? 'pi-chevron-up' : 'pi-chevron-down'" />
    </a>

    <p-data-table
      v-if="dataSource && showStudentsDetail"
      :value="dataSource"
      dataKey="id"
      class="text-sm col-12"
      size="small"
      :loading="scoreDiagnostic.loading"
      v-model:filters="filters"
      removableSort
      sortMode="multiple"
      paginator
      scrollable
      :rows="10"
      :rowsPerPageOptions="[5, 10, 20, 50]"
      :globalFilterFields="['firstname', 'lastname', 'teacherFullname']"
    >
      <template #header>
        <div class="col-12 grid flex justify-content-start align-items-center max-h-min">
          <p-input-group class="col-2 max-h-min h-4rem">
            <p-input-text
              autocomplete="off"
              data-lpignore="true"
              data-form-type="other"
              class="text-base"
              placeholder="Chercher un élève"
              v-model="filters['global'].value"
              size="small"
            />
          </p-input-group>
          <p-input-group class="col-3 max-h-min h-4rem">
            <p-dropdown
              v-model="filters['teacherFullname'].value"
              :options="teachersOptions"
              optionLabel="label"
              optionValue="value"
              placeholder="Filtrer par enseignant référent"
              size="small"
            />
          </p-input-group>
        </div>
      </template>
      <template #empty> Aucun utilisateur trouvé.</template>
      <template #loading> Loading customers data. Please wait.</template>

      <!-- Body -->
      <p-column field="firstname" frozen :sortable="true" header="Prénom" class="w-12rem">
        <template #body="{ data }">
          <p class="Center">{{ (data as IUser).firstname }}</p>
        </template>
      </p-column>

      <p-column field="lastname" frozen :sortable="true" header="Nom" class="w-12rem">
        <template #body="{ data }">
          <p class="Center">{{ (data as IUser).lastname }}</p>
        </template>
      </p-column>

      <p-column field="teacherFullname" :sortable="true" header="Enseignant" class="w-12rem">
        <template #body="{ data }">
          <p class="Center">{{ data.teacherFullname ? data.teacherFullname : '-' }}</p>
        </template>
      </p-column>

      <template v-if="scoreDiagnostic.data && scoreDiagnostic.data[0]?.tasks">
        <p-column
          v-for="task in scoreDiagnostic.data[0].tasks"
          :key="task.taskTitle"
          :field="task.taskId.toString()"
          :sortable="false"
          :header="task.taskTitle"
          class="w-12rem"
        >
          <template #body="{ data }">
            <p class="Center">
              <theme-table-score
                :score="
                  data.tasks.find((t) => t.taskId === task.taskId)?.diagScorePercentRounded || 0
                "
                :eval-range="data.tasks.find((t) => t.taskId === task.taskId)?.diagEvalRangeName"
              />
            </p>
          </template>
        </p-column>
      </template>
    </p-data-table>
  </dashboard-base-block>
</template>

<style scoped>
.p-chart {
  height: 400px;
}
</style>
