<script setup lang="ts">
import { inject, onMounted, ref, type Ref } from 'vue'
import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'
import QuizView from '@/components/quiz/QuizView.vue'

const dialogRef: any = inject('dialogRef')
const quiz: Ref<ILearningQuiz> = ref({} as ILearningQuiz)
const subtitle: Ref<string> = ref('')

onMounted(() => {
  quiz.value = dialogRef.value?.data?.quiz
  subtitle.value = dialogRef.value?.data?.subtitle
})
</script>

<template>
  <quiz-view :subtitle="subtitle" :quiz="quiz" />
</template>

<style lang="scss" scoped>
:global(.p-dialog-header) {
  color: var(--bo-primary);
}

:global(.p-dialog-content) {
  color: var(--bo-primary);
}
</style>
