import { computed } from 'vue'
import { Gender, Role } from '@/assets/types/UserTypes'
import { Degree } from '@/assets/types/school/ISchoolClassroom'
import { storeToRefs } from 'pinia'
import { useSchoolStore } from '@/stores/school'
import type { ISchool } from '@/assets/types/school/ISchool'
import type { ISchoolYear } from '@/assets/types/school/ISchoolYear'
import { useSchoolYearStore } from '@/stores/schoolYear'
import { useReferentialStore } from '@/stores/referential'

export const useGenderOptions = computed(() => {
  return Object.keys(Gender.Values)
    .filter((key): key is keyof typeof Gender.Values => key in Gender.Values)
    .map((genderKey) => ({
      name: Gender.label(Gender.Values[genderKey]),
      code: genderKey
    }))
})

export const useRoleOptions = computed(() => {
  return Object.keys(Role.Values)
    .filter((key): key is keyof typeof Role.Values => key in Role.Values)
    .map((roleKey) => ({
      name: Role.label(Role.Values[roleKey]),
      code: roleKey
    }))
})

export const useDegreeOptions = computed(() => {
  return Object.keys(Degree.Values)
    .filter((key): key is keyof typeof Degree.Values => key in Degree.Values)
    .map((degreeKey) => ({
      name: Degree.label(Degree.Values[degreeKey]),
      code: degreeKey
    }))
})

export const useSchoolOptions = computed(() => {
  const { schoolList } = storeToRefs(useSchoolStore())
  return schoolList.value.map((school: ISchool) => ({
    name: school.name,
    code: school.id
  }))
})

export const useSchoolYearOptions = computed(() => {
  const { schoolYearList } = storeToRefs(useSchoolYearStore())
  return schoolYearList.value
    .sort((sy1: ISchoolYear, sy2: ISchoolYear) => sy1.startDate.getTime() - sy2.startDate.getTime())
    .map((schoolYear: ISchoolYear) => ({
      name: schoolYear.name,
      code: schoolYear.id
    }))
})

export const useAxisesOptions = computed(() => {
  const { referential } = storeToRefs(useReferentialStore())
  return referential.value.map((axis) => ({
    name: axis.title,
    code: axis.id
  }))
})

export type DataOption = {
  label: string
  value: string
}

// Create a generic function to convert an enum to data options
export const generateDataOptionsFromEnum = <T extends { [key: string]: string | number }>(
  enumObj: T,
  labelFunc?: (value: string | number) => string
): DataOption[] => {
  return Object.values(enumObj).map((value) => ({
    label: labelFunc ? labelFunc(value) : value.toString(),
    value: value as string
  }))
}
