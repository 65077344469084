<script setup lang="ts">
import type { IQuestion } from '@/assets/types/learning/QuizQuestion'
import { computed, defineProps } from 'vue'
import { default as PPanel } from 'primevue/panel'
import { default as PAccordion } from 'primevue/accordion'
import { default as PAccordionTab } from 'primevue/accordiontab'
import { LearningEnums } from '@/assets/types/learning/enums'
import type {
  IMatchingAnswer,
  IMCQAnswer,
  ISequencingAnswer,
  ITrueFalseAnswer,
  IUCQAnswer
} from '@/assets/types/learning/answers'
import QuizChoiceAnswer from '@/components/quiz/answers/QuizChoiceAnswer.vue'
import QuizSequencingAnswer from '@/components/quiz/answers/QuizSequencingAnswer.vue'
import QuizMatchingAnswer from '@/components/quiz/answers/QuizMatchingAnswer.vue'

const props = defineProps<{
  question: IQuestion
}>()

const labelType = computed(() => {
  if (props.question.type === LearningEnums.QuestionType.SINGLE_CHOICE) {
    return 'QCU'
  } else if (props.question.type === LearningEnums.QuestionType.MULTIPLE_CHOICE) {
    return 'QCM'
  } else if (props.question.type === LearningEnums.QuestionType.TRUE_FALSE) {
    return 'Vrai/Faux'
  } else if (props.question.type === LearningEnums.QuestionType.MATCHING) {
    return 'Associer'
  } else if (props.question.type === LearningEnums.QuestionType.SEQUENCING) {
    return 'Réordonner'
  } else {
    return 'Inconnu'
  }
})

const isChoiceQuestion = computed(() => {
  return (
    props.question.type === LearningEnums.QuestionType.SINGLE_CHOICE ||
    props.question.type === LearningEnums.QuestionType.MULTIPLE_CHOICE ||
    props.question.type === LearningEnums.QuestionType.TRUE_FALSE
  )
})

const isMatchingQuestion = computed(() => {
  return props.question.type === LearningEnums.QuestionType.MATCHING
})

const isSequencingQuestion = computed(() => {
  return props.question.type === LearningEnums.QuestionType.SEQUENCING
})

const answers = computed(() => {
  if (props.question.type === LearningEnums.QuestionType.SINGLE_CHOICE) {
    return props.question.answers as IUCQAnswer[]
  } else if (props.question.type === LearningEnums.QuestionType.MULTIPLE_CHOICE) {
    return props.question.answers as IMCQAnswer[]
  } else if (props.question.type === LearningEnums.QuestionType.TRUE_FALSE) {
    return props.question.answers as ITrueFalseAnswer[]
  } else if (props.question.type === LearningEnums.QuestionType.MATCHING) {
    return props.question.answers as IMatchingAnswer[]
  } else if (props.question.type === LearningEnums.QuestionType.SEQUENCING) {
    return props.question.answers as ISequencingAnswer[]
  } else {
    return []
  }
})
</script>

<template>
  <p-panel class="p-2 border-bottom-none bg-white" :class="$style.QuestionPanel">
    <template #header>
      <h5 class="font-bold text-lg">Question {{ question.order }}</h5>
    </template>
    <template #icons>
      <p>{{ labelType }}</p>
    </template>

    <p-accordion :multiple="true" :active-index="[0]">
      <p-accordion-tab>
        <template #header>
          <h6 class="font-bold">{{ question.label }}</h6>
        </template>

        <div class="flex flex-column justify-content-start gap-1">
          <template v-if="isChoiceQuestion">
            <quiz-choice-answer
              v-for="(answer, idx) in answers"
              :key="`choice_${question.id}_${answer}_${idx}`"
              :answer="answer"
            />
          </template>
          <template v-else-if="isMatchingQuestion">
            <QuizMatchingAnswer
              v-for="(answer, idx) in answers"
              :key="`matching_${question.id}_${answer}_${idx}`"
              :answer="answer as IMatchingAnswer"
              :order="idx + 1"
            />
          </template>
          <template v-else-if="isSequencingQuestion">
            <quiz-sequencing-answer
              v-for="(answer, idx) in answers"
              :key="`sequencing_${question.id}_${answer}_${idx}`"
              :answer="answer as ISequencingAnswer"
            />
          </template>
          <template v-else>
            <p>Type de question non-reconnu</p>
          </template>
        </div>
      </p-accordion-tab>
    </p-accordion>
  </p-panel>
</template>

<style lang="scss" module>
.QuestionPanel {
  color: var(--bo-primary);

  :global(.p-panel-header) {
    border-bottom: 0;
    padding: 0.5rem 0.75rem;
    color: var(--bo-primary);
    background-color: var(--bo-white);
  }

  :global(.p-panel-content) {
    padding: 0.25rem 0.75rem;
  }

  :global(.p-accordion-header-link) {
    padding: 0;
    border-radius: 0;
    background-color: var(--bo-white);
    color: var(--bo-primary);
    border: 0;
  }

  :global(.p-accordion-content) {
    border: 0;
    padding: 0;
  }
}
</style>
