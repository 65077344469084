import type {
  INabooContentAxisDto,
  INabooContentDiagnosticDto,
  INabooContentDiagnosticTaskDto,
  INabooContentLearningCourseChildrenDto,
  INabooContentLearningCourseDto,
  INabooContentLearningGrainChildrenDto,
  INabooContentLearningGrainDto,
  INabooContentLearningGrainQuizDto,
  INabooContentLearningModuleDto,
  INabooContentLearningSupportDto
} from '@/assets/DTO/nabooContent/nabooContent.types.dto'
import type { IAxis, ILearningTask } from '@/assets/types/Referential'
import type { ILearningCourse } from '@/assets/types/learning/LearningCourses'
import type { ILearningModule } from '@/assets/types/learning/LearningModuleTypes'
import type { ILearningGrain } from '@/assets/types/learning/LearningGrainTypes'
import type { ILearningSupport } from '@/assets/types/learning/LearningSupportTypes'
import type { ILearningQuiz } from '@/assets/types/learning/learningQuiz'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { IQuestion } from '@/assets/types/learning/QuizQuestion'

/**
 * Implementation of an axis in the Naboo content
 * @implements INabooContentAxisDto
 * @param axis - The axis to represent
 * @param learningCourses - The learning courses to represent
 */
export class NabooContentAxisDto implements INabooContentAxisDto {
  id: number = 0
  title: string = ''
  order: number = 0
  learningCourses: NabooContentLearningCourseDto[] = []

  constructor(axis: IAxis, learningCourses: ILearningCourse[]) {
    this.id = axis.id
    this.title = axis.title
    this.order = axis.order
    this.learningCourses =
      learningCourses.map((learningCourse) => new NabooContentLearningCourseDto(learningCourse)) ??
      []
  }
}

/**
 * Implementation of a Learning Course in the Naboo content
 * @implements INabooContentLearningCourseDto
 * @param learningCourse - The learning course to represent
 */
export class NabooContentLearningCourseDto implements INabooContentLearningCourseDto {
  id: number = 0
  title: string = ''
  order: number = 0
  name: string = ''
  diagnostic: NabooContentDiagnosticDto
  learningModules: NabooContentLearningModuleDto[] = []

  constructor(learningCourse: ILearningCourse) {
    this.id = learningCourse.id
    this.title = learningCourse.title
    this.order = learningCourse.order
    this.name = learningCourse.name
    this.diagnostic = new NabooContentDiagnosticDto(learningCourse)
    this.learningModules =
      learningCourse.learningModules.map(
        (learningModule) => new NabooContentLearningModuleDto(learningModule)
      ) ?? []
  }

  get children(): INabooContentLearningCourseChildrenDto[] {
    return [this.diagnostic, ...this.learningModules].sort((a, b) => a.order - b.order)
  }
}

/**
 * Implementation of a Learning Module in the Naboo content
 * @implements INabooContentLearningModuleDto
 * @param learningModule - The learning module to represent
 */
export class NabooContentLearningModuleDto implements INabooContentLearningModuleDto {
  id: number = 0
  title: string = ''
  order: number = 0
  name: string
  learningCourseId: number
  learningGrains: NabooContentLearningGrainDto[] = []

  constructor(learningModule: ILearningModule) {
    this.id = learningModule.id
    this.title = learningModule.title
    this.order = learningModule.order
    this.name = learningModule.learningCourseName
    this.learningCourseId = learningModule.learningCourseId
    this.learningGrains =
      learningModule.learningGrains.map(
        (learningGrain) => new NabooContentLearningGrainDto(learningGrain)
      ) ?? []
  }

  get countLearningGrains(): number {
    return this.learningGrains.length
  }
}

/**
 * Implementation of a Learning Grain in the Naboo content
 * @implements INabooContentLearningGrainDto
 * @param learningGrain - The learning grain to represent
 */
export class NabooContentLearningGrainDto implements INabooContentLearningGrainDto {
  id: number = 0
  title: string = ''
  order: number = 0
  quizPre: NabooContentLearningGrainQuizDto
  quizPost: NabooContentLearningGrainQuizDto
  learningSupports: NabooContentLearningSupportDto[] = []

  constructor(learningGrain: ILearningGrain) {
    this.id = learningGrain.id
    this.title = learningGrain.title
    this.order = learningGrain.order
    this.quizPre = new NabooContentLearningGrainQuizDto(
      learningGrain.quizPre,
      this.calculateQuizPreOrder()
    )
    this.quizPost = new NabooContentLearningGrainQuizDto(
      learningGrain.quizPost,
      this.calculateQuizPostOrder()
    )
    this.learningSupports =
      learningGrain.learningSupports.map(
        (learningSupport) => new NabooContentLearningSupportDto(learningSupport)
      ) ?? []
  }

  private calculateQuizPreOrder(): number {
    const items = this.learningSupports.map((support) => support.order).sort((a, b) => a - b)
    return items && items.length > 0 ? items[0] - 1 : 0
  }

  private calculateQuizPostOrder(): number {
    const items = this.learningSupports.map((support) => support.order).sort((a, b) => b - a)
    return items && items.length > 0 ? items[0] + 1 : 99
  }

  get estimatedTime(): number {
    return this.learningSupports
      .map((support) => support.estimatedTime)
      .reduce((acc, estimatedTime) => acc + estimatedTime, 0)
  }

  get children(): INabooContentLearningGrainChildrenDto[] {
    return [this.quizPre, ...this.learningSupports, this.quizPost].sort((a, b) => a.order - b.order)
  }
}

/**
 * Implementation of a Learning Support in the Naboo content
 * @implements INabooContentLearningSupportDto
 * @param learningSupport - The learning support to represent
 */
export class NabooContentLearningSupportDto implements INabooContentLearningSupportDto {
  id: number = 0
  title: string = ''
  order: number = 0
  url: string = ''
  estimatedTime: number = 0

  constructor(learningSupport: ILearningSupport) {
    this.id = learningSupport.id
    this.title = `Support ${learningSupport.order}`
    this.order = learningSupport.order
    this.url = learningSupport.url
    this.estimatedTime = learningSupport.estimatedTime
  }
}

/**
 * Implementation of a Diagnostic in the Naboo content
 * @implements INabooContentDiagnosticDto
 * @param learningCourse - The learning course to represent
 */
export class NabooContentDiagnosticDto implements INabooContentDiagnosticDto {
  id: number = 0
  title: string = 'Diagnostic'
  order: number = 0
  name: string
  learningCourseId: number
  learningCourseTitle: string
  tasks: NabooContentDiagnosticTaskDto[] = []

  constructor(learningCourse: ILearningCourse) {
    this.id = learningCourse.id
    this.order = this.calculateOrder(learningCourse)
    this.name = learningCourse.name
    this.learningCourseId = learningCourse.id
    this.learningCourseTitle = learningCourse.title
    this.tasks =
      learningCourse.domain.tasks
        .filter((t) => t.quiz.ready)
        .map((task) => new NabooContentDiagnosticTaskDto(task)) ?? []
  }

  private calculateOrder(learningCourse: ILearningCourse): number {
    const items = learningCourse.learningModules.map((module) => module.order).sort((a, b) => a - b)
    return items && items.length > 0 ? items[0] - 1 : 0
  }

  get countQuestions(): number {
    return this.tasks.reduce((acc, task) => acc + task.countQuestions, 0)
  }
}

/**
 * Implementation of a Diagnostic Task in the Naboo content
 * @implements INabooContentDiagnosticTaskDto
 * @param task - The task to represent
 */
export class NabooContentDiagnosticTaskDto implements INabooContentDiagnosticTaskDto {
  id: number = 0
  title: string = ''
  order: number = 0
  quiz: ILearningQuiz

  constructor(task: ILearningTask) {
    this.id = task.id
    this.title = task.title
    this.order = task.order
    this.quiz = task.quiz
  }

  get countQuestions(): number {
    return this.quiz.questions.length
  }

  get questions(): IQuestion[] {
    return this.quiz.questions
  }
}

/**
 * Implementation of a Learning Grain Quiz in the Naboo content
 * @implements INabooContentLearningGrainQuizDto
 * @param learningQuiz - The learning quiz to represent
 * @param order - The order of the quiz
 */
export class NabooContentLearningGrainQuizDto implements INabooContentLearningGrainQuizDto {
  id: number = 0
  title: string = ''
  order: number = 0
  quiz: ILearningQuiz

  constructor(learningQuiz: ILearningQuiz, order: number = 0) {
    this.id = learningQuiz.id
    this.title = this.getTitle(learningQuiz)
    this.order = order
    this.quiz = learningQuiz
  }

  private getTitle(learningQuiz: ILearningQuiz): string {
    if (learningQuiz.type === LearningEnums.QuizType.PRE.toUpperCase()) {
      return "Quiz d'introduction"
    } else if (learningQuiz.type === LearningEnums.QuizType.POST.toUpperCase()) {
      return 'Quiz de contrôle des connaissances'
    } else {
      throw new UnknownQuizTypeNabooContent(learningQuiz.type)
    }
  }

  get countQuestions(): number {
    return this.quiz.questions.length
  }
}

// Exceptions
export class UnknownQuizTypeNabooContent extends Error {
  constructor(type: LearningEnums.QuizType) {
    super(`Le type de Quiz ${type} est incompatible`)
  }
}
