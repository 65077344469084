import type { IUpdateState, IUserInput } from '@/assets/types/UserTypes'
import { Gender, Role } from '@/assets/types/UserTypes'

export class UpdateUserStateInputDto implements IUpdateState {
  userIdentifier: string
  state: boolean

  constructor(obj: UpdateUserStateInputDto) {
    this.userIdentifier = obj.userIdentifier
    this.state = obj.state
  }
}

export class UserInputDto implements IUserInput {
  identifier: string
  firstname: string
  lastname: string
  nickname: string
  email: string
  gender: Gender.Values
  roles: Role.Values[] = []
  birthdate?: string // toISOString() -> UTC
  blocked: boolean

  constructor(obj: UserInputDto) {
    this.identifier = obj.identifier
    this.firstname = obj.firstname
    this.lastname = obj.lastname
    this.nickname = obj.nickname
    this.email = obj.email
    this.gender = obj.gender
    this.roles = obj.roles
    this.birthdate = obj.birthdate
    this.blocked = obj.blocked
  }
}
