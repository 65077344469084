<script setup lang="ts">
import { computed } from 'vue'
import { useSchoolClassroomStore } from '@/stores/schoolClassroom'
import { storeToRefs } from 'pinia'
import { default as PCard } from 'primevue/card'
import { useDialog } from 'primevue/usedialog'
import GroupDetailListUser from '@/views/dashboard/blocks/details/GroupDetailListUserDialog.vue'

const { classroom } = storeToRefs(useSchoolClassroomStore())
const dialog = useDialog()

const cards = computed(() => [
  {
    key: 'students',
    title: 'élève(s)',
    value: classroom.value?.data?.students?.length ?? 0,
    icon: 'pi pi-user',
    action: () => {
      dialog.open(GroupDetailListUser, {
        props: {
          header: 'Liste des élèves',
          modal: true,
          dismissableMask: true,
          draggable: false,
          style: 'width: 50vw'
        },
        data: { users: classroom.value?.data?.students }
      })
    }
  },
  {
    key: 'teachers',
    title: 'enseignant(s) référent(s)',
    value: classroom.value?.data?.teachers?.length ?? 0,
    icon: 'pi pi-user',
    action: () => {
      dialog.open(GroupDetailListUser, {
        props: {
          header: 'Liste des enseignants référents',
          modal: true,
          dismissableMask: true,
          draggable: false,
          style: 'width: 50vw'
        },
        data: { users: classroom.value?.data?.teachers }
      })
    }
  }
])
</script>

<template>
  <div class="grid-12 flex flex-row flex-wrap sm:gap-2 md:gap-4 justify-content-start max-h-min">
    <p-card
      v-for="card in cards"
      :key="`card_${card.key}`"
      class="flex-initial align-items-start cursor-pointer"
      :class="$style['metric-card']"
      @click="card.action"
    >
      <template #title>
        <i class="font-bold text-lg" :class="card.icon" />
        <span class="ml-2 text-lg">{{ card.value }}</span>
      </template>
      <template #content>
        <span class="text-base">{{ card.title }}</span>
        <i class="pi pi-arrow-right text-primary ml-2 vertical-align-middle" />
      </template>
    </p-card>
  </div>
</template>

<style lang="scss" module>
.metric-card :global(.p-card-content) {
  padding: 0;
}

.metric-card :global(.p-card-body) {
  padding: 1rem;
}
</style>
