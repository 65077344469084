<script setup lang="ts">
import { default as PButton } from 'primevue/button'
import { default as PTable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { default as PSidebar } from 'primevue/sidebar'
import { default as PTag } from 'primevue/tag'
import type { ILearningModule } from '@/assets/types/learning/LearningModuleTypes'
import { ref } from 'vue'
import { LearningEnums } from '@/assets/types/learning/enums'
import { useModulesStore } from '@/stores/learningModules'
import { useRouter } from 'vue-router'

const router = useRouter()
const props = defineProps<{
  modulesList: ILearningModule[]
  learningCourseId: number
}>()

const moduleToAchive = ref<ILearningModule>({} as ILearningModule)

const { updateModuleStatus } = useModulesStore()

function createModule() {
  router.push({ name: 'learning-course-add-module', params: { id: props.learningCourseId } })
}

async function archiveModule(module: ILearningModule) {
  await updateModuleStatus(LearningEnums.Status.ARCHIVED, module.id, props.learningCourseId)

  moduleToAchive.value = {} as ILearningModule
}
</script>

<template>
  <div class="col-12 mt-3">
    <div class="mb-3 flex align-items-center justify-content-between">
      <h2>Liste des modules</h2>
      <p-button
        @click="createModule()"
        class="ml-auto"
        label="Nouveau module"
        icon="pi pi-plus"
        size="small"
        outlined
        severity="primary"
      />
    </div>

    <p-table :value="modulesList">
      <template #empty>
        <div class="w-full flex justify-content-center align-center text-color-secondary p-5">
          Aucun module n'éxiste
        </div>
      </template>
      <!--        <p-column field="modifiedAt" header="Modifié le" style="width: 8rem" />-->
      <p-column
        field="title"
        header="Titre du module"
        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden"
      />
      <p-column
        field="task"
        header="Tâche liée"
        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden"
      >
        <template #body="{ data }: { data: ILearningModule }">
          <span class="w-full overflow-hidden block text-overflow-ellipsis">{{
            data.task?.title
          }}</span>
        </template>
      </p-column>
      <p-column
        field="learningGrains"
        header="Grain "
        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden"
      >
        <template #body="{ data }: { data: ILearningModule }">
          <span class="w-full overflow-hidden block text-overflow-ellipsis">{{
            data.learningGrains?.length
          }}</span>
        </template>
      </p-column>
      <p-column field="status" header="Statut" style="width: 8rem">
        <template #body="{ data }: { data: ILearningModule }">
          <p-tag :severity="LearningEnums.statusColor(data.status)" rounded>
            {{ LearningEnums.statusLabel(data.status) }}
          </p-tag>
        </template>
      </p-column>
      <p-column header="Actions" style="width: 8rem">
        <template #body="{ data }: { data: ILearningModule }">
          <div class="gap-2">
            <router-link
              :to="{
                name: 'learning-course-update-module',
                params: { id: learningCourseId, moduleId: data.id }
              }"
            >
              <p-button icon="pi pi-pencil" rounded text />
            </router-link>
            <p-button icon="pi pi-trash" rounded text @click="moduleToAchive = data" />
          </div>
        </template>
      </p-column>
    </p-table>
  </div>

  <p-sidebar
    :visible="!!moduleToAchive.title"
    position="right"
    class="w-4"
    :show-close-icon="false"
  >
    <template #header>
      <div class="w-full p-5 flex justify-content-between">
        <p-button
          text
          icon="pi pi-times"
          @click="moduleToAchive = {} as ILearningModule"
          severity="secondary"
        />
        <h3 class="text-right">Suppression du module {{ moduleToAchive.title }}</h3>
      </div>
    </template>

    <template #default>
      <div class="w-full p-5 flex flex-column justify-center align-center gap-5 text-color h-full">
        <h5>Êtes-vous sûr.e de vouloir supprimer le module {{ moduleToAchive.title }} ?</h5>
        <p class="text-color-secondary">
          Attention, cette action est irréversible. Si vous cliquez sur valider, votre module sera
          définitivement supprimé.
        </p>

        <p-button
          label="Valider la suppression"
          class="justify-self-end mt-auto"
          @click="archiveModule(moduleToAchive)"
        ></p-button>
      </div>
    </template>
  </p-sidebar>
</template>
