<script setup lang="ts">
import { default as PDatatable } from 'primevue/datatable'
import { default as PColumn } from 'primevue/column'
import { inject, onMounted, type Ref, ref } from 'vue'
import { SchoolUserResponseDto } from '@/assets/DTO/school/school.user.response.dto'

const dialogRef: any = inject('dialogRef')
const title: Ref<string> = ref('')
const users: Ref<SchoolUserResponseDto[]> = ref([])

onMounted(() => {
  title.value = dialogRef.value?.data?.title
  users.value = dialogRef.value?.data?.users
})
</script>

<template>
  <div class="grid">
    <p-datatable :value="users" class="col-12">
      <p-column header="Prénom" field="firstname" />
      <p-column header="Nom" field="lastname" />
    </p-datatable>
  </div>
</template>
