export interface IUser {
  identifier: string
  firstname: string
  lastname: string
  nickname: string
  email: string
  isEmailVerified: boolean
  gender: Gender.Values
  roles: Role.Values[]
  birthdate?: Date
  picture?: string
  hasAnsweredTrackingConsentBanner: boolean
  hasConsentedForTracking: boolean
  isBlocked: boolean
}

export interface IUserDetails extends IUser {
  schoolId: number | null
  schoolName: string | null
}

export interface IUserInput {
  identifier: string
  firstname: string
  lastname: string
  nickname: string
  email: string
  gender: Gender.Values
  roles: Role.Values[]
  birthdate?: string // toISOString() -> UTC
  blocked: boolean
}

// Interface to update a state (email verified, blocked, etc.)
export interface IUpdateState {
  userIdentifier: string
  state: boolean
}

export namespace Role {
  export enum Values {
    ADMIN = 'ADMIN',
    EDU_ENGINEER = 'EDU_ENGINEER',
    COORDINATOR = 'COORDINATOR',
    TEACHER = 'TEACHER',
    TUTOR = 'TUTOR',
    STUDENT = 'STUDENT'
  }

  export function label(authority: Values) {
    switch (authority) {
      case Values.ADMIN:
        return 'Administrateur'
      case Values.EDU_ENGINEER:
        return 'Ing. Pédagogique'
      case Values.COORDINATOR:
        return 'Coordinateur'
      case Values.TEACHER:
        return 'Professeur'
      case Values.TUTOR:
        return 'Tuteur'
      case Values.STUDENT:
        return 'Étudiant'
      default:
        return 'Non défini'
    }
  }
}

export namespace Gender {
  export enum Values {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
    UNSPECIFIED = 'UNSPECIFIED'
  }

  export function label(gender: Values) {
    switch (gender) {
      case Values.MALE:
        return 'Masculin'
      case Values.FEMALE:
        return 'Féminin'
      case Values.OTHER:
        return 'Autre'
      case Values.UNSPECIFIED:
        return 'Non spécifié'
      default:
        return 'Non défini'
    }
  }
}
