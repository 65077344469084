<script setup lang="ts">
import { default as PCard } from 'primevue/card'
import { default as PTag } from 'primevue/tag'
import type { INabooContentLearningCourseChildrenDto } from '@/assets/DTO/nabooContent/nabooContent.types.dto'
import { computed } from 'vue'
import {
  NabooContentDiagnosticDto,
  NabooContentLearningModuleDto
} from '@/assets/DTO/nabooContent/nabooContent.dto'
import { useRouter } from 'vue-router'
import { useDialog } from 'primevue/usedialog'
import NabooContentDiagnosticDialog from '@/views/nabooContent/blocks/NabooContentDiagnosticDialog.vue'

// PROPS
const props = defineProps<{
  children: INabooContentLearningCourseChildrenDto
}>()

// SERVICES
const router = useRouter()
const dialog = useDialog()

const getSubtitle = computed(() => {
  if (props.children instanceof NabooContentLearningModuleDto) {
    return `Mission ${props.children.name}-${props.children.order}`
  } else if (props.children instanceof NabooContentDiagnosticDto) {
    return "Test d'entrée"
  } else {
    return ''
  }
})

const getContent = computed(() => {
  if (props.children instanceof NabooContentLearningModuleDto) {
    return `${(props.children as NabooContentLearningModuleDto).countLearningGrains} session(s)`
  } else if (props.children instanceof NabooContentDiagnosticDto) {
    return `${(props.children as NabooContentDiagnosticDto).countQuestions} question(s)`
  } else {
    return ''
  }
})

type CardActionObject = {
  label: string
  action: () => void
}

const getFooter = computed<CardActionObject>(() => {
  if (props.children instanceof NabooContentLearningModuleDto) {
    return {
      label: 'module',
      action: async () => {
        await router.push({
          name: 'naboo-content-module',
          params: {
            courseId: props.children.learningCourseId,
            moduleId: props.children.id
          }
        })
      }
    }
  } else if (props.children instanceof NabooContentDiagnosticDto) {
    return {
      label: 'diagnostic',
      action: async () => {
        const input = props.children as NabooContentDiagnosticDto
        dialog.open(NabooContentDiagnosticDialog, {
          props: {
            header: `Parcours - ${input.learningCourseTitle}`,
            modal: true,
            dismissableMask: true,
            draggable: false,
            style: 'width: 50vw',
            breakpoints: {
              '960px': '50vw',
              '640px': '95vw'
            }
          },
          data: {
            diagnostic: input,
            subtitle: input.title
          }
        })
      }
    }
  } else {
    return {
      label: '',
      action: () => {}
    }
  }
})
</script>

<template>
  <p-card
    class="flex-1 shadow-none border-solid border-1 border-black-alpha-20"
    :class="$style.AspectCard"
  >
    <template #title>
      <span class="text-base text-900">{{ children.title }}</span>
    </template>
    <template #subtitle>
      <p-tag class="uppercase" severity="warning">{{ getSubtitle }}</p-tag>
    </template>
    <template #content>
      <p class="font-italic text-900 text-sm">{{ getContent }}</p>
    </template>
    <template #footer>
      <p @click="getFooter.action()" class="underline cursor-pointer text-sm text-blue-500">
        Voir le contenu du {{ getFooter.label }}
      </p>
    </template>
  </p-card>
</template>

<style lang="scss" module>
.AspectCard {
  display: flex;
  color: var(--bo-primary);
  max-width: 300px;

  :global(.p-card-body) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
  }

  :global(.p-card-footer) {
    align-self: end;
    width: 100%;
  }
}
</style>
