import API from '@/services/API'
import type { IUpdateState, IUserDetails, IUserInput } from '@/assets/types/UserTypes'
import {
  GetUsersResponseDto,
  UserResponseDto,
  UserWithDetailsResponseDto
} from '@/assets/DTO/users/user.response.dto'
import { NabooError } from '@/assets/classes/Error'
import * as Sentry from '@sentry/vue'
import type { AxiosResponse } from 'axios'

export const users = {
  async getAllUsers() {
    try {
      const response = await API().get('admin/users')
      return Promise.resolve(new GetUsersResponseDto({ users: response.data }))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/users.ts - getAllUsers')
        error.setMessage('Une erreur est survenue lors de la récupération des données.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des données.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  },

  /**
   * Get all users with details (schoolId, schoolName)
   */
  async getAllUsersWithDetails(): Promise<UserWithDetailsResponseDto[]> {
    try {
      const response: AxiosResponse<IUserDetails[]> = await API().get('admin/users/details')
      return Promise.resolve(response.data.map((user) => new UserWithDetailsResponseDto(user)))
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/users.ts - getAllUsersWithDetails')
        error.setMessage('Une erreur est survenue lors de la récupération des utilisateurs.')
        Sentry.captureException(error)
      }
      return Promise.reject(error)
    }
  },

  async getUserByIdentifier(userIdentifier: string) {
    try {
      const response = await API().get(`admin/users/${userIdentifier}`)
      return Promise.resolve(new UserResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/users.ts - getUserByIdentifier')
        error.setMessage('Une erreur est survenue lors de la récupération des données.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des données.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  },

  async createUser(user: IUserInput) {
    try {
      const response = await API().post('admin/users', user)

      if (response.status === 201) return Promise.resolve()
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/users.ts - createUser')
        error.setMessage("Une erreur est survenue lors de la création de l'utilisateur.")
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: "Une erreur est survenue lors de la création de l'utilisateur.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  },

  async updateUser(user: IUserInput) {
    try {
      const response = await API().put(`admin/users/${user.identifier}`, user)

      if (response.status === 200) return Promise.resolve()
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/users.ts - updateUser')
        error.setMessage("Une erreur est survenue lors de la mise à jour de l'utilisateur.")
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: "Une erreur est survenue lors de la mise à jour de l'utilisateur.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  },

  async setVerifiedEmailState(input: IUpdateState) {
    try {
      const response = await API().put(`admin/users/${input.userIdentifier}/email/verify`, {
        state: input.state
      })
      return Promise.resolve(new UserResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/users.ts - setVerifiedEmailState')
        error.setMessage("Une erreur est survenue lors de la mise à jour de l'email vérifié.")
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: "Une erreur est survenue lors de la mise à jour de l'email vérifié.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  },

  async setBlockedState(input: IUpdateState) {
    try {
      const response = await API().put(`admin/users/${input.userIdentifier}/blocked`, {
        state: input.state
      })
      return Promise.resolve(new UserResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/users.ts - setBlockedState')
        error.setMessage("Une erreur est survenue lors de la mise à jour de l'utilisateur bloqué.")
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: "Une erreur est survenue lors de la mise à jour de l'utilisateur bloqué.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  }
}
