<script setup lang="ts">
/* ---------------------
--------IMPORTS---------
----------------------*/
//PrimeVue
import { default as PButton } from 'primevue/button'
import { default as PImage } from 'primevue/image'
import { default as PAvatar } from 'primevue/avatar'
import { default as PMenu } from 'primevue/menu'
import type { MenuItem } from 'primevue/menuitem'

//Vue & Vue Router
import { computed, nextTick, type Ref, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// Custom
import largeLogoUrl from '@/assets/images/logo-naboo-bleu.svg'
import smallLogoUrl from '@/assets/images/logo-naboo-bleu-small.svg'
import { useProfileStore } from '@/stores/profile'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'

import { createNabooAdminRoutes } from '@/router/routes'
import { Role } from '@/assets/types/UserTypes'
const routes = createNabooAdminRoutes()

const route = useRoute()
const router = useRouter()
const { toggleMenuOpen } = useProfileStore()
const { isMenuOpened, getPicture, getFullName, getFirstNameInitial } =
  storeToRefs(useProfileStore())
const { initLogout } = useAuthStore()
const { getRoles } = storeToRefs(useAuthStore())

// DATA, PROPS & REFS
const isFullyOpened = ref(isMenuOpened.value)

/**
 * Fonction récursive pour parcourir les routes et collecter les rôles
 * @param node
 */
function collectRolesFromRoute(node: any): Role.Values[] {
  // Utiliser un Set pour garantir l'unicité des valeurs
  const rolesSet = new Set<string>()

  const traverse = (currentNode: any) => {
    // Vérifier si "meta" existe et contient "roles", et que c'est une liste valide
    if (currentNode?.meta?.roles && Array.isArray(currentNode.meta.roles)) {
      currentNode.meta.roles.forEach((role: string | null | undefined) => {
        if (role) {
          rolesSet.add(role) // Ajouter les rôles non null et non undefined dans le Set
        }
      })
    }

    // Si le noeud a des enfants, les parcourir récursivement
    if (currentNode?.children && Array.isArray(currentNode.children)) {
      currentNode.children.forEach((child: any) => traverse(child))
    }
  }

  // Démarrer la récursion sur le noeud de départ
  traverse(node)

  // Convertir le Set en tableau et retourner la liste unique des rôles
  return Array.from(rolesSet).map((role) => role as Role.Values)
}

/**
 * Fonction pour construire les éléments du menu
 */
const menuItems = computed(() => {
  return routes
    .filter((it) => it.name !== 'NotFound')
    .map((it) => {
      const routeRoles = collectRolesFromRoute(it)

      if (routeRoles.length > 0 && !routeRoles.some((role) => getRoles.value.includes(role))) {
        return null
      }
      const link = 'name' in it ? it.name : it?.children.length > 0 ? it.children[0].name : ''

      return {
        name: it.meta?.menuTitle,
        link: link,
        icon: it.meta?.icon,
        group: it.meta?.group
      }
    })
    .filter((it) => it !== null)
})

// Avatar menu
const menuWrapper: Ref<HTMLElement | null> = ref(null)
const menuAvatar = ref()
const items: Ref<MenuItem[]> = ref([
  {
    label: 'Se déconnecter',
    command: () => initLogout()
  }
])

// METHODS
async function processClick(link: string) {
  link === '/' ? await router.replace({ path: '/' }) : await router.push({ name: link })
}

function toggleMenu() {
  if (!isMenuOpened.value) {
    nextTick(() => {
      isFullyOpened.value = true
    })
  } else {
    nextTick(() => {
      isFullyOpened.value = false
    })
  }
  toggleMenuOpen()
  //isOpened.value = !isOpened.value
}

const toggle = (event: Event) => {
  menuAvatar.value.toggle(event)
}

// COMPUTED PROPERTIES
const logoUrl = computed(() => {
  return isFullyOpened.value ? largeLogoUrl : smallLogoUrl
})

const currentGroup = computed(() => {
  return route?.meta?.group ?? 'home'
})
</script>

<template>
  <div
    class="_m-Lateral-Menu relative"
    :class="isMenuOpened ? 'opened' : ['closed', 'flex', 'flex-column', 'align-items-center']"
    ref="menuWrapper"
  >
    <!--Logo-->
    <p-image v-if="isFullyOpened" :src="logoUrl" image-class="w-full max-h-3rem" />
    <p-image v-else :src="logoUrl" class="block max-w-3rem" />

    <!--Profile-->
    <p-button
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      text
      class="p-1 mt-3 w-full"
      :class="isFullyOpened ? 'justify-content-between' : 'justify-content-center'"
    >
      <p-avatar v-if="getPicture" :image="getPicture" shape="circle" />
      <p-avatar v-else :label="getFirstNameInitial?.toLocaleUpperCase()" shape="circle" />
      <span v-if="isFullyOpened">{{ getFullName }}</span>
      <i class="pi pi-chevron-down ml-2" v-if="isFullyOpened"></i>
    </p-button>
    <p-menu ref="menuAvatar" id="overlay_menu" :model="items" :popup="true" />

    <div
      class="_m-Lateral-Menu-Nav mt-3 flex flex-column"
      :class="{ 'align-items-center': !isFullyOpened }"
    >
      <div
        v-for="(item, i) in menuItems"
        :key="`link_${i}`"
        class="_m-Lateral-Menu-Nav__Item flex align-items-center my-2"
        :class="[currentGroup === item.group ? 'selected' : '']"
        @click="processClick(item.link)"
      >
        <i class="pi flex justify-content-center align-items-center" :class="[item.icon]"></i>
        <h5 v-if="isFullyOpened" class="ml-1 font-bold text-sm">{{ item.name }}</h5>
      </div>
    </div>

    <p-button
      @click="toggleMenu()"
      icon="pi pi-arrow-right"
      text
      rounded
      aria-label="toggle menu"
      class="absolute border-none right-0 bottom-0 _m-Lateral-Menu-Toggle"
    />
  </div>
</template>

<style scoped lang="scss">
._m-Lateral-Menu {
  height: 100vh;
  border-right: 1px solid var(--gray-400);
  transition: all ease 0.3s;
  padding: 16px 12px;
  background-color: #fff;

  // Variants
  &.opened {
    width: 220px;

    .p-button._m-Lateral-Menu-Toggle {
      position: relative;
      transform: rotateZ(180deg);
    }
  }

  &.closed {
    max-width: 105px;
  }

  ._m-Lateral-Menu-Nav {
    ._m-Lateral-Menu-Nav__Item {
      border-radius: 6px;
      transition: all ease 0.3s;

      &:hover {
        cursor: pointer;

        i {
          color: var(--blue-400);
          background-color: var(--gray-100);
        }
      }

      i {
        width: 40px;
        height: 38px;
        color: var(--gray-500);
        background-color: transparent;
        border-radius: 6px;
        transition: all ease 0.3s;
      }

      //state of component
      &.selected {
        i {
          color: var(--blue-400);
          background-color: var(--blue-100);
        }
      }
    }
  }
}
</style>
