<script setup lang="ts">
import type { NabooContentLearningCourseDto } from '@/assets/DTO/nabooContent/nabooContent.dto'
import { default as PAccordion } from 'primevue/accordion'
import { default as PAccordionTab } from 'primevue/accordiontab'
import { default as PTag } from 'primevue/tag'
import { defineProps, type Ref, ref } from 'vue'
import NabooContentListCard from '@/views/nabooContent/blocks/NabooContentListCard.vue'

// PROPS
const props = defineProps<{
  learningCourses: NabooContentLearningCourseDto[]
}>()

// STATE
const activeIndexes: Ref<number[]> = ref([...Array(props.learningCourses.length).keys()])
</script>

<template>
  <p-accordion
    :multiple="true"
    :active-index="activeIndexes"
    class="col-12"
    :class="$style.CourseAccordion"
  >
    <p-accordion-tab v-for="course in props.learningCourses" :key="course.id">
      <template #header>
        <div
          class="flex flex-row flex-wrap flex-initial justify-content-start align-content-center align-items-center gap-2 vertical-align"
        >
          <h4 class="flex-auto">Parcours - {{ course.title }}</h4>
          <p-tag class="flex-1 uppercase min-w-max" severity="warning">{{ course.name }}</p-tag>
        </div>
      </template>
      <div class="flex flex-wrap gap-3 justify-content-start">
        <naboo-content-list-card
          v-for="child in course.children"
          :key="`card_${child.id}_${child.order}`"
          :children="child"
        />
      </div>
    </p-accordion-tab>
  </p-accordion>
</template>

<style lang="scss" module>
.CourseAccordion {
  :global(.p-accordion-header-link) {
    padding: 1rem;
    border-radius: 0;
    background-color: var(--bo-white);
    color: var(--bo-primary);
    border: 0;
  }

  :global(.p-accordion-content) {
    border: 0;
    padding: 5px;
  }
}

.ContentColumn {
  justify-items: start;
}
</style>
