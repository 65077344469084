<script setup lang="ts">
import { default as PCard } from 'primevue/card'
import type { INabooContentLearningGrainChildrenDto } from '@/assets/DTO/nabooContent/nabooContent.types.dto'
import { computed } from 'vue'
import {
  NabooContentLearningGrainDto,
  NabooContentLearningGrainQuizDto,
  NabooContentLearningSupportDto
} from '@/assets/DTO/nabooContent/nabooContent.dto'
import { useDialog } from 'primevue/usedialog'
import NabooContentQuizDialog from '@/views/nabooContent/blocks/NabooContentQuizDialog.vue'

// PROPS
const props = defineProps<{
  grain: NabooContentLearningGrainDto
  children: INabooContentLearningGrainChildrenDto
}>()

// SERVICES
const dialog = useDialog()

// ACTIONS
const openQuizDialog = async (
  input: NabooContentLearningGrainQuizDto,
  grain: NabooContentLearningGrainDto
) => {
  dialog.open(NabooContentQuizDialog, {
    props: {
      header: `Grain ${grain.order} : ${grain.title}`,
      modal: true,
      dismissableMask: true,
      draggable: false,
      style: 'width: 50vw',
      breakpoints: {
        '960px': '50vw',
        '640px': '95vw'
      }
    },
    data: {
      quiz: input.quiz,
      subtitle: input.title
    }
  })
}

// COMPUTED
const getContent = computed(() => {
  if (props.children instanceof NabooContentLearningSupportDto) {
    return `${(props.children as NabooContentLearningSupportDto).url}`
  } else if (props.children instanceof NabooContentLearningGrainQuizDto) {
    return `${(props.children as NabooContentLearningGrainQuizDto).countQuestions} question(s)`
  } else {
    return ''
  }
})
</script>

<template>
  <p-card
    class="flex-1 shadow-none border-solid border-1 border-black-alpha-20"
    :class="$style.AspectCard"
  >
    <template #title>
      <div class="flex flex-row justify-content-around align-content-start w-full">
        <span class="flex-auto text-base text-900 align-self-center vertical-align-middle">{{
          children.title
        }}</span>
        <span
          v-if="children instanceof NabooContentLearningGrainQuizDto"
          class="flex-initial align-self-stretch cursor-pointer text-blue-500"
          @click="() => openQuizDialog(children as NabooContentLearningGrainQuizDto, grain)"
        >
          <i class="pi pi-eye" />
        </span>
        <a
          v-else
          :href="(children as NabooContentLearningSupportDto).url"
          target="_blank"
          class="flex-initial align-self-stretch vertical-align-top text-blue-500"
        >
          <i class="pi pi-external-link" />
        </a>
      </div>
    </template>
    <template #content>
      <p class="font-italic text-900 text-sm" :class="$style.ContentCard">
        {{ getContent }}
      </p>
    </template>
  </p-card>
</template>

<style lang="scss" module>
.AspectCard {
  display: flex;
  color: var(--bo-primary);
  max-width: 300px;
  min-width: 200px;

  :global(.p-card-body) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    align-content: start;
    width: 100%;
  }

  :global(.p-card-caption) {
    width: 100%;
  }

  :global(.p-card-content) {
    align-items: start;
    align-content: start;
    align-self: start;
    vertical-align: top;
  }
}

.ContentCard {
  word-break: break-all;
}
</style>
