import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'
import type { SchoolClassroomResponseDto } from '@/assets/DTO/school/school.classroom.response.dto'
import {
  SchoolClassroomGroupUserLoginHistoryDto,
  SchoolClassroomScoreDiagnosticDto,
  SchoolClassroomScoreModuleDto
} from '@/assets/DTO/school/school.classroom.response.dto'
import { NabooError } from '@/assets/classes/Error'
import nabooApi from '@/services/nabooApi'

export interface ISchoolClassroomState {
  data: SchoolClassroomResponseDto
  loading: boolean
  hasError: boolean
  errorMessage: string
}

export interface ISchoolClassroomLoginHistoryState {
  data: SchoolClassroomGroupUserLoginHistoryDto
  loading: boolean
  hasError: boolean
  errorMessage: string
}

export interface ISchoolClassroomScoreDiagnosticState {
  data: SchoolClassroomScoreDiagnosticDto[]
  loading: boolean
  hasError: boolean
  errorMessage: string
}

export interface ISchoolClassroomScoreModuleState {
  data: SchoolClassroomScoreModuleDto[][]
  loading: boolean
  hasError: boolean
  errorMessage: string
}

/**
 * School classroom store
 */
export const useSchoolClassroomStore = defineStore('SchoolClassroom', () => {
  // STATE
  const classroom: Ref<ISchoolClassroomState> = ref({
    data: {} as SchoolClassroomResponseDto,
    loading: false,
    hasError: false,
    errorMessage: ''
  })
  const loginHistory: Ref<ISchoolClassroomLoginHistoryState> = ref({
    data: {} as SchoolClassroomGroupUserLoginHistoryDto,
    loading: false,
    hasError: false,
    errorMessage: ''
  })
  const scoreDiagnostic: Ref<ISchoolClassroomScoreDiagnosticState> = ref({
    data: [],
    loading: false,
    hasError: false,
    errorMessage: ''
  })
  const scoreModule: Ref<ISchoolClassroomScoreModuleState> = ref({
    data: [],
    loading: false,
    hasError: false,
    errorMessage: ''
  })

  // ACTIONS
  /**
   * Fetch school classroom by id
   * @param schoolId
   * @param schoolClassroomId
   */
  async function fetchSchoolClassroomById(schoolId: number, schoolClassroomId: number) {
    try {
      // Set flags
      classroom.value.loading = true
      classroom.value.hasError = false
      classroom.value.errorMessage = ''

      // Fetch data
      const response: SchoolClassroomResponseDto = await nabooApi.getSchoolClassroomById(
        schoolId,
        schoolClassroomId
      )
      // Set data
      if (response) classroom.value.data = response
      return Promise.resolve()
    } catch (error) {
      classroom.value.hasError = true
      if (error instanceof NabooError) {
        classroom.value.errorMessage = error.message
      }
      return Promise.reject()
    } finally {
      classroom.value.loading = false
    }
  }

  /**
   * Fetch school classroom login history
   * @param schoolId
   * @param schoolClassroomId
   */
  async function fetchSchoolClassroomLoginHistory(schoolId: number, schoolClassroomId: number) {
    try {
      // Set flags
      loginHistory.value.loading = true
      loginHistory.value.hasError = false
      loginHistory.value.errorMessage = ''

      // Fetch data
      const response: SchoolClassroomGroupUserLoginHistoryDto =
        await nabooApi.getSchoolClassroomLoginHistory(schoolId, schoolClassroomId)

      // Set data
      if (response) loginHistory.value.data = response
      return Promise.resolve()
    } catch (error: any) {
      loginHistory.value.hasError = true
      if (error instanceof NabooError) {
        loginHistory.value.errorMessage = error.message
      }
    } finally {
      loginHistory.value.loading = false
    }
  }

  /**
   * Fetch school classroom score diagnostic
   * @param schoolId
   * @param schoolClassroomId
   * @param learningCourseId
   */
  async function fetchSchoolClassroomScoreDiagnostic(
    schoolId: number,
    schoolClassroomId: number,
    learningCourseId: number
  ) {
    try {
      // Set flags
      scoreDiagnostic.value.loading = true
      scoreDiagnostic.value.hasError = false
      scoreDiagnostic.value.errorMessage = ''

      // Fetch data
      const response: SchoolClassroomScoreDiagnosticDto[] =
        await nabooApi.getSchoolClassroomScoreDiagnostic(
          schoolId,
          schoolClassroomId,
          learningCourseId
        )

      // Set data
      if (response) scoreDiagnostic.value.data = response
      return Promise.resolve()
    } catch (error: any) {
      scoreDiagnostic.value.hasError = true
      if (error instanceof NabooError) {
        scoreDiagnostic.value.errorMessage = error.message
      }
    } finally {
      scoreDiagnostic.value.loading = false
    }
  }

  /**
   * Fetch school classroom score module
   * @param schoolId
   * @param schoolClassroomId
   * @param taskId
   */
  async function fetchSchoolClassroomScoreModule(
    schoolId: number,
    schoolClassroomId: number,
    taskId: number
  ) {
    try {
      // Set flags
      scoreModule.value.loading = true
      scoreModule.value.hasError = false
      scoreModule.value.errorMessage = ''

      // Fetch data
      const response: SchoolClassroomScoreModuleDto[] =
        await nabooApi.getSchoolClassroomScoreModule(schoolId, schoolClassroomId, taskId)

      // Set data
      if (response) {
        scoreModule.value.data[taskId] = response
      }
      return Promise.resolve()
    } catch (error: any) {
      scoreModule.value.hasError = true
      if (error instanceof NabooError) {
        scoreModule.value.errorMessage = error.message
      }
    } finally {
      scoreModule.value.loading = false
    }
  }

  function resetClassroom() {
    classroom.value = {
      data: {} as SchoolClassroomResponseDto,
      loading: false,
      hasError: false,
      errorMessage: ''
    }
  }

  function resetLoginHistory() {
    loginHistory.value = {
      data: {} as SchoolClassroomGroupUserLoginHistoryDto,
      loading: false,
      hasError: false,
      errorMessage: ''
    }
  }

  function resetScoreDiagnostic() {
    scoreDiagnostic.value = {
      data: [],
      loading: false,
      hasError: false,
      errorMessage: ''
    }
  }

  function resetScoreModule() {
    scoreModule.value = {
      data: [],
      loading: false,
      hasError: false,
      errorMessage: ''
    }
  }

  // GETTERS (COMPUTED)

  return {
    // STATE
    classroom,
    loginHistory,
    scoreDiagnostic,
    scoreModule,

    // ACTIONS
    fetchSchoolClassroomById,
    fetchSchoolClassroomLoginHistory,
    fetchSchoolClassroomScoreDiagnostic,
    fetchSchoolClassroomScoreModule,
    resetClassroom,
    resetLoginHistory,
    resetScoreDiagnostic,
    resetScoreModule
  }
})
