import { defineStore } from 'pinia'
import {
  NabooContentAxisDto,
  NabooContentLearningModuleDto
} from '@/assets/DTO/nabooContent/nabooContent.dto'
import { type Ref, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { LearningEnums } from '@/assets/types/learning/enums'

export const useNabooContentStore = defineStore('NabooContent', () => {
  const listAxis: Ref<NabooContentAxisDto[]> = ref([])
  const currentLearningModule: Ref<NabooContentLearningModuleDto | null> = ref(null)

  // ACTIONS
  async function fetchNabooContent() {
    try {
      const learningCoursesOptions = {
        withModules: true,
        withGrains: true,
        withSupports: false,
        withDomains: true,
        status: [LearningEnums.Status.ACTIVE]
      }

      // Fetch data to prepare Naboo content
      const [axis, learningCourses] = await Promise.all([
        nabooApi.getAllAxis(),
        nabooApi.getAllCourses(learningCoursesOptions)
      ])

      // Prepare Naboo content
      listAxis.value = axis.referential
        .map((axis) => {
          const courses = learningCourses.courses.filter((course) =>
            axis.domains.map((domain) => domain.id).includes(course.domain.id)
          )
          return new NabooContentAxisDto(axis, courses)
        })
        .filter((axis) => axis.learningCourses.length > 0)
    } catch (error) {
      if (error instanceof Error) {
        error.message = 'Une erreur est survenue lors de la récupération des données.'
      }
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  async function fetchNabooContentModule(learningCourseId: number, learningModuleId: number) {
    try {
      const learningModuleOptions = {
        withGrains: true,
        withSupports: true,
        withTasks: true,
        status: [LearningEnums.Status.ACTIVE]
      }
      const learningModule = await nabooApi.getModuleById(
        learningModuleId,
        learningCourseId,
        learningModuleOptions
      )
      currentLearningModule.value = new NabooContentLearningModuleDto(learningModule)
    } catch (error) {
      if (error instanceof Error) {
        error.message = 'Une erreur est survenue lors de la récupération du module.'
      }
      return Promise.reject(error)
    }
    return Promise.resolve()
  }

  return {
    listAxis,
    currentLearningModule,
    fetchNabooContent,
    fetchNabooContentModule
  }
})
