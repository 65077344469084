<script setup lang="ts">
import type { IMatchingAnswer } from '@/assets/types/learning/answers'
import { computed } from 'vue'

const props = defineProps<{
  answer: IMatchingAnswer
  order: number
}>()

const letters = 'abcdefghijklmnopqrstuvwxyz'.split('')
const prefixes = computed(() => {
  return { left: props.order, right: letters[props.order - 1] ?? '-' }
})
</script>

<template>
  <div class="flex-1 flex flex-column">
    <p class="flex-1 w-full">Paire {{ order }}</p>

    <div class="flex-1 flex flex-row gap-2">
      <!-- LEFT -->
      <div
        class="flex-1 flex flex-row flex-shrink-1 justify-content-start p-0 border-1 border-solid border-round-lg"
        :class="$style.MatchingAnswer"
      >
        <div
          class="py-2 flex-initial w-3rem text-center surface-100 border-right-1 border-400 border-round-left-lg"
          :class="$style.prefix"
        >
          <p>{{ prefixes.left }}</p>
        </div>
        <p class="px-2 py-2 flex-1 flex-grow-1">{{ answer.left.value }}</p>
      </div>
      <!-- RIGHT -->
      <div
        class="flex-1 flex flex-row justify-content-start p-0 border-1 border-solid border-round-lg"
        :class="$style.MatchingAnswer"
      >
        <div
          class="py-2 flex-initial w-3rem text-center surface-100 border-right-1 border-400 border-round-left-lg"
          :class="$style.prefix"
        >
          <p>{{ prefixes.right }}</p>
        </div>
        <p class="px-2 py-2 flex-1 flex-grow-1">{{ answer.right.value }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.MatchingAnswer {
  border-color: var(--surface-400);

  p {
    cursor: default;
  }

  &.prefix {
    width: 50px;
    max-width: 30px;
    border-right: 1px solid black;
    padding-left: 4rem;
    background-color: var(--surface-900);
  }
}
</style>
