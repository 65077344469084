import router from '@/router'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export namespace AdminSentry {
  export const init = (app: App, router: Router) => {
    Sentry.init({
      app,
      dsn: __SENTRY_DSN__,
      environment: __ENV_NAME__,
      release: `${__APP_NAME__}@${__APP_VERSION__}`,
      tracingOptions: {
        trackComponents: ['VUpdateModule', 'VModulesList', 'VUsers'],
        hooks: ['mount', 'update'],
        timeout: 500
      },
      integrations: [
        Sentry.browserTracingIntegration(
          {
            router,
            routeLabel: 'name'
          }
        ),
        Sentry.replayIntegration()
      ],
      tracePropagationTargets: ['localhost', __BASE_URL_API__, 'https://.*.genial.ly/.*'],
      // Performance Monitoring
      tracesSampleRate: __SENTRY_TRACE_SAMPLE_RATE__ ?? 0.1,
      // Session Replay
      replaysSessionSampleRate: __SENTRY_REPLAY_SESSION_SAMPLE_RATE__ ?? 0.1,
      replaysOnErrorSampleRate: __SENTRY_REPLAY_ERROR_SAMPLE_RATE__ ?? 0.1
    })
  }
}
