import type { ILearningCourse } from '@/assets/types/learning/LearningCourses'
import type { ILearningDomain } from '@/assets/types/Referential'
import type { ILearningModule } from '@/assets/types/learning/LearningModuleTypes'
import { LearningEnums } from '@/assets/types/learning/enums'

export class GetAllLearningCoursesResponseDto {
  courses: LearningCourseResponseDto[]

  constructor(obj: ILearningCourse[]) {
    this.courses = obj.map((course) => new LearningCourseResponseDto(course))
  }
}

export class LearningCourseResponseDto implements ILearningCourse {
  id: number
  title: string
  status: LearningEnums.Status
  order: number
  name: string
  domain: ILearningDomain
  learningModules: ILearningModule[]

  constructor(obj: ILearningCourse) {
    this.id = obj.id
    this.title = obj.title
    this.status = obj.status
    this.order = obj.order
    this.name = obj.name
    this.domain = obj.domain
    this.learningModules = obj.learningModules
  }

  modulesCount() {
    return this.learningModules.length
  }

  get activeModules() {
    return this.learningModules.filter((module) => module.status === LearningEnums.Status.ACTIVE)
  }

  isValid() {
    return this.title !== '' && this.domain !== null && this.activeModules.length > 0
  }

  isActive() {
    return this.status === LearningEnums.Status.ACTIVE
  }
}

export interface ILearningCourseNames {
  names: string[]
}

export class LearningCourseNamesResponseDto implements ILearningCourseNames {
  names: string[]

  constructor(obj: ILearningCourseNames) {
    this.names = obj.names.map((name) => name.toLowerCase())
  }

  exists(name: string) {
    return this.names.includes(name.toLowerCase())
  }
}
