import { defineStore } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { computed, type Ref, ref } from 'vue'
import type { IUpdateState, IUser, IUserInput } from '@/assets/types/UserTypes'
import { Role } from '@/assets/types/UserTypes'
import nabooApi from '@/services/nabooApi'
import router from '@/router'
import type { GetUsersResponseDto } from '@/assets/DTO/users/user.response.dto'
import { UserWithDetailsResponseDto } from '@/assets/DTO/users/user.response.dto'
import { NabooError } from '@/assets/classes/Error'

export const useUserStore = defineStore('users', () => {
  // UTILITY FUNCTIONS
  const toast = useToast()

  // STATE
  const loading: Ref<boolean> = ref(false)
  const usersList: Ref<IUser[]> = ref([])

  // ACTIONS
  async function getAllUsers() {
    try {
      loading.value = true
      const res: GetUsersResponseDto = await nabooApi.getAllUsers()

      usersList.value = res.users
      usersList.value.forEach((user: IUser) => {
        user.birthdate = user.birthdate ? new Date(user.birthdate) : undefined
      })
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: 'Impossible de récupérer la liste des utilisateurs',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      usersList.value = [] as IUser[]
      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })
    } finally {
      loading.value = false
    }
  }

  /**
   * Fetch all users with details (schoolId, schoolName)
   */
  async function fetchAllUsersWithDetails() {
    try {
      loading.value = true
      const users: UserWithDetailsResponseDto[] = await nabooApi.getAllUsersWithDetails()
      if (users != null) usersList.value = users
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: (error as NabooError).name,
        detail: (error as NabooError).message,
        life: 3000
      })
    } finally {
      loading.value = false
    }
  }

  async function getUserByIdentifier(userIdentifier: string) {
    try {
      loading.value = true
      const user = await nabooApi.getUserByIdentifier(userIdentifier)

      user.birthdate = user.birthdate ? new Date(user.birthdate) : undefined
      if (user.identifier)
        usersList.value.splice(
          usersList.value.findIndex((u: IUser) => u.identifier === user.identifier),
          1,
          user
        )
      return user
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: "Impossible de récupérer l'utilisateur",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })
    } finally {
      loading.value = false
    }
  }

  async function createUser(userInput: IUserInput) {
    loading.value = true

    try {
      await nabooApi.createUser(userInput)

      toast.add({
        severity: 'success',
        summary: 'Utilisateur créé',
        detail: `L'utilisateur ${userInput.firstname} ${userInput.lastname} a été créé avec succès`,
        life: 3000
      })
      await router.push({ name: 'users-list' })
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: "Impossible de créer l'utilisateur",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })
    } finally {
      loading.value = false
    }
  }

  async function updateUser(userInput: IUserInput) {
    loading.value = true

    try {
      await nabooApi.updateUser(userInput)
      toast.add({
        severity: 'success',
        summary: 'Utilisateur mis à jour',
        detail: `L'utilisateur ${userInput.firstname} ${userInput.lastname} a été mis à jour avec succès`,
        life: 3000
      })
      await router.push({ name: 'users-list' })
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: "Impossible de mettre à jour l'utilisateur",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })
    } finally {
      loading.value = false
    }
  }

  async function setVerifiedEmailState(input: IUpdateState) {
    loading.value = true
    try {
      const user = await nabooApi.setVerifiedEmailState(input)

      if (user.identifier)
        usersList.value.splice(
          usersList.value.findIndex((u: IUser) => u.identifier === user.identifier),
          1,
          user
        )

      toast.add({
        severity: 'success',
        summary: 'Email vérifié',
        detail: `L'email de ${user.firstname} ${user.lastname} a été vérifié avec succès`,
        life: 3000
      })
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: "Impossible de mettre à jour l'email vérifié",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })
    } finally {
      loading.value = false
    }
  }

  async function setBlockedState(input: IUpdateState) {
    loading.value = true
    try {
      const user = await nabooApi.setBlockedState(input)
      if (user.identifier)
        usersList.value.splice(
          usersList.value.findIndex((u: IUser) => u.identifier === user.identifier),
          1,
          user
        )

      toast.add({
        severity: 'success',
        summary: 'Utilisateur bloqué',
        detail: `L'utilisateur ${user.firstname} ${user.lastname} a été bloqué avec succès`,
        life: 3000
      })
    } catch (error) {
      let err: NabooError

      if (error instanceof NabooError) {
        err = error
      } else {
        err = new NabooError({
          message: "Impossible de bloquer l'utilisateur",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      toast.add({
        severity: 'error',
        summary: err.name,
        detail: err.message,
        life: 3000
      })
    } finally {
      loading.value = false
    }
  }

  const userListIsEmpty = computed(() => usersList.value.length === 0)

  const getTeachers = computed(() => {
    return usersList.value.filter((user: IUser) => user.roles.includes(Role.Values.TEACHER))
  })

  const getStudents = computed(() => {
    return usersList.value.filter((user: IUser) => user.roles.includes(Role.Values.STUDENT))
  })

  const getTutors = computed(() => {
    return usersList.value.filter((user: IUser) => user.roles.includes(Role.Values.TUTOR))
  })

  return {
    // STATE
    loading,
    usersList,

    // GETTERS
    userListIsEmpty,
    getTeachers,
    getStudents,
    getTutors,

    // ACTIONS
    getAllUsers,
    fetchAllUsersWithDetails,
    getUserByIdentifier,
    createUser,
    updateUser,
    setVerifiedEmailState,
    setBlockedState
  }
})
