import type {
  ILearningModule,
  ILearningModuleInput,
  IModuleTask
} from '@/assets/types/learning/LearningModuleTypes'
import { LearningEnums } from '@/assets/types/learning/enums'
import type { ILearningGrain } from '@/assets/types/learning/LearningGrainTypes'
import { LearningGrainResponseDto } from '@/assets/DTO/learning/learningGrain.response.dto'

export class LearningModuleResponseDto implements ILearningModule {
  id: number
  title: string
  order: number
  status: LearningEnums.Status
  task: IModuleTask
  learningCourseId: number
  learningCourseName: string
  learningGrains: ILearningGrain[]
  createdBy: string
  createdAt: Date
  modifiedBy: string
  modifiedAt: Date

  constructor(data: ILearningModule) {
    this.id = data.id
    this.title = data.title
    this.status = data.status
    this.order = data.order
    this.task = data.task
    this.learningGrains = data.learningGrains.sort((a,b) => a.order - b.order).map((grain,i) => new LearningGrainResponseDto(grain, i + 1))
    this.learningCourseId = data.learningCourseId
    this.learningCourseName = data.learningCourseName
    this.createdBy = data.createdBy
    this.createdAt = data.createdAt
    this.modifiedBy = data.modifiedBy
    this.modifiedAt = data.modifiedAt
  }

  createLearninModuleIpnut(): ILearningModuleInput {
    return {
      id: this.id,
      title: this.title,
      status: this.status,
      order: this.order,
      taskId: this.task.id,
      learningGrains: this.learningGrains
    }
  }

  grainsCount(): number {
    return this.learningGrains.length
  }

  activeGrains(): ILearningGrain[] {
    return this.learningGrains.filter((grain) => grain.status === LearningEnums.Status.ACTIVE)
  }
}
